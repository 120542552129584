<template>
  <q-page padding>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">
        {{ advertiserSelect ? $t('edit') : $t('registration_of') }} Anunciante/ Agência
      </q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
        <q-breadcrumbs-el :label="$t('manage') + ' Advertiser'" to="/advertiser" />
        <q-breadcrumbs-el label="Advertiser" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />
    <q-form @submit="formSubmit">
      <q-card flat class="q-mt-md">
        <q-card-section class="row items-center">
          <q-stepper
            v-model="step"
            ref="formAdvertiser"
            color="primary"
            animated
            :header-nav="!!advertiserSelect"
            alternative-labels
            flat
            class="full-width"
          >
            <q-step name="company" :title="$t('company')" icon="mdi-domain" :done="step > 1">
              <q-form @submit="$refs.formAdvertiser.next()">
                <q-card flat>
                  <q-separator />
                  <q-card-section class="row q-col-gutter-md q-pa-none">
                    <div class="col-12 q-gutter-sm">
                      <q-item class="q-pl-none">
                        <q-item-section side>
                          <q-item-label class="text-weight-medium">{{ $t('type') }}: </q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-radio v-model="form.type" :disable = "advertiserSelect ? true : false" val="anunciante" :label="$t('advertiser')" />
                        </q-item-section>
                        <q-item-section side>
                          <q-radio v-model="form.type" :disable = "advertiserSelect ? true : false" val="agencia" :label="$t('agency')" />
                        </q-item-section>
                      </q-item>
                    </div>
                    <div class="col-6">
                      <q-input
                        input-style="text-transform:uppercase"
                        outlined
                        v-model="form.cnpj"
                        type="text"
                        :label="$t('cnpj_registration_number') + ' *'"
                        lazy-rules
                        maxlength="25"
                        :rules="[isRequired, cnpjExists]"
                        @input="validateCnpjAndFetchData"
                        ref="cnpjInputRef"
                        hint="Preencher apenas com números"
                      >
                      </q-input>
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        type="text"
                        :label="form.type == 'anunciante' ? $t('fantasy_name') : $t('agency')"
                        v-model="form.trademark"
                        lazy-rules
                        :rules="[isRequired]"
                      />
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.business_name"
                        type="text"
                        :label="$t('corporate_name')"
                        lazy-rules
                        :rules="[isRequired]"
                        :readonly="isCnpjDataAutoFilled"
                      />
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.zip_code"
                        type="text"
                        :label="$t('postal_code') + ' *'"
                        lazy-rules
                        :rules="[isRequired]"
                        mask="#####-###"
                        unmasked-value
                        hint="Ex. 99999-999"
                        :readonly="isCnpjDataAutoFilled"
                      />
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.address"
                        type="text"
                        :label="$t('address') + ' *'"
                        lazy-rules
                        :rules="[isRequired]"
                        :readonly="isCnpjDataAutoFilled"
                      />
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.address_number"
                        type="text"
                        :label="$t('number') + ' *'"
                        lazy-rules
                        :rules="[isRequired]"
                        :readonly="isCnpjDataAutoFilled"
                      />
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.address_other"
                        type="text"
                        :label="$t('complement') + ' *'"
                        lazy-rules
                        :readonly="isCnpjDataAutoFilled"
                      />
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.city"
                        type="text"
                        :label="$t('city') + ' *'"
                        lazy-rules
                        :rules="[isRequired]"
                        :readonly="isCnpjDataAutoFilled"
                      />
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.state"
                        type="text"
                        :label="$t('state') + ' *'"
                        lazy-rules
                        :rules="[isRequired]"
                        :readonly="isCnpjDataAutoFilled"
                      />
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.country"
                        type="text"
                        :label="$t('country') + ' *'"
                        lazy-rules
                        :rules="[isRequired]"
                        :readonly="isCnpjDataAutoFilled"
                      />
                    </div>
                  </q-card-section>
                  <q-separator spaced />
                  <q-card-actions align="right">
                    <q-btn
                      :label="$t('cancel')"
                      padding="sm lg"
                      outline
                      color="primary"
                      unelevated
                      no-caps
                      @click="onClose"
                    />
                    <q-btn :label="$t('next')" padding="sm lg" unelevated no-caps type="submit" color="primary" />
                  </q-card-actions>
                </q-card>
              </q-form>
            </q-step>

            <q-step name="contact" :title="$t('business_contact')" icon="mdi-card-account-phone-outline">
              <q-form @submit="$refs.formAdvertiser.next()">
                <q-card flat>
                  <q-separator />
                  <q-card-section class="row q-col-gutter-md q-pa-none q-pt-lg">
                    <div class="col-12">
                      <q-input
                        outlined
                        type="text"
                        :label="$t('name') + ' *'"
                        v-model="form.contact_name"
                        lazy-rules
                        :rules="[isRequired]"
                      />
                    </div>
                    <div class="col-12">
                      <q-input
                        outlined
                        v-model="form.contact_email"
                        type="email"
                        label="Email *"
                        lazy-rules
                        :rules="[isRequired, isEmail]"
                      />
                    </div>
                    <div class="col-12">
                      <q-input
                        outlined
                        v-model="form.phone_number"
                        type="tel"
                        mask="+## ## #####-####"
                        unmasked-value
                        hint="Ex. +55 21 99999-9999"
                        :label="$t('phone') + ' *'"
                        lazy-rules
                        :rules="[isRequired]"
                      />
                    </div>
                  </q-card-section>
                  <q-separator spaced />
                  <q-card-actions align="right">
                    <q-btn
                      :label="$t('cancel')"
                      padding="sm lg"
                      outline
                      color="primary"
                      unelevated
                      no-caps
                      @click="onClose"
                    />
                    <q-btn
                      :label="$t('back')"
                      padding="sm lg"
                      outline
                      color="primary"
                      unelevated
                      no-caps
                      @click="$refs.formAdvertiser.previous()"
                    />
                    <q-btn :label="$t('next')" padding="sm lg" unelevated no-caps type="submit" color="primary" />
                  </q-card-actions>
                </q-card>
              </q-form>
            </q-step>

            <q-step name="revenue" :title="$t('billing')" icon="assignment">
              <q-form @submit="$refs.formAdvertiser.next()">
                <q-card flat>
                  <q-card-section class="row q-col-gutter-md q-pa-none">
                    <div class="col-12 q-gutter-sm">
                      <q-item class="q-pl-none">
                        <q-item-section side>
                          <q-item-label class="text-weight-medium">{{ $t('nationality') }}: </q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-radio v-model="form.invoice_type" val="brasil" :label="$t('brazil')" />
                        </q-item-section>
                        <q-item-section side>
                          <q-radio v-model="form.invoice_type" val="internacional" :label="$t('international')" />
                        </q-item-section>
                      </q-item>
                    </div>
                    <div class="col-6">
                      <!-- :readonly="isCnpjDataAutoFilled" -->
                      <q-input
                        input-style="text-transform:uppercase"
                        outlined
                        v-model="form.invoice_registration_code"
                        type="text"
                        :label="$t('cnpj_registration_number') + ' *'"
                        lazy-rules
                        maxlength="25"
                        :rules="[isRequired]"
                        @input="getCnpjDataForInvoice"
                        hint="Preencher apenas com números"
                      />
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.invoice_company_name"
                        type="text"
                        :label="$t('corporate_name')"
                        lazy-rules
                        :rules="[isRequired]"
                        :readonly="isCnpjDataAutoFilledInvoice"
                      />
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.invoice_inscricao_estadual"
                        type="number"
                        :label="$t('state_registration')"
                        lazy-rules
                        :rules="[isRequired]"
                        :disable="isento"
                      >
                        <template v-slot:after>
                          <q-checkbox left-label v-model="isento" :label="$t('free')" class="text-caption" />
                        </template>
                      </q-input>
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.invoice_inscricao_municipal"
                        type="number"
                        :label="$t('municipal_registration')"
                      />
                    </div>
                    <div class="col-3">
                      <q-input
                        outlined
                        v-model="form.invoice_postal_code"
                        type="text"
                        :label="$t('postal_code') + ' *'"
                        mask="#####-###"
                        unmasked-value
                        hint="Ex. 99999-999"
                        lazy-rules
                        :rules="[isRequired]"
                        :readonly="isCnpjDataAutoFilledInvoice"
                      />
                    </div>
                    <div class="col-9">
                      <q-input
                        outlined
                        v-model="form.invoice_address"
                        type="text"
                        :label="$t('address') + ' *'"
                        lazy-rules
                        :rules="[isRequired]"
                        :readonly="isCnpjDataAutoFilledInvoice"
                      />
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.invoice_city"
                        type="text"
                        :label="$t('city') + ' *'"
                        lazy-rules
                        :rules="[isRequired]"
                        :readonly="isCnpjDataAutoFilledInvoice"
                      />
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.invoice_state"
                        type="text"
                        :label="$t('state') + ' *'"
                        lazy-rules
                        :rules="[isRequired]"
                        :readonly="isCnpjDataAutoFilledInvoice"
                      />
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.invoice_phone"
                        type="text"
                        mask="+## ## #####-####"
                        unmasked-value
                        hint="Ex. +55 21 99999-9999"
                        :label="$t('phone') + ' *'"
                        lazy-rules
                        :rules="[isRequired]"
                      />
                    </div>
                    <div class="col-6">
                      <q-input
                        outlined
                        v-model="form.invoice_deadline"
                        type="number"
                        :label="$t('billing_deadline') + ' *'"
                        lazy-rules
                        :rules="[isRequired]"
                        :hint="$t('in_days')"
                      />
                    </div>
                    <div class="col-12">
                      <q-input
                        outlined
                        v-model="form.invoice_email"
                        hint="Para adicionar mais de 1 e-mail, por favor, separe-os com ;"
                        type="text"
                        label="Email para envio da fatura"
                        lazy-rules
                        :rules="[isRequired]"
                      />
                    </div>
                  </q-card-section>
                  <q-card-actions align="right">
                    <q-btn
                      :label="$t('cancel')"
                      padding="sm lg"
                      outline
                      color="primary"
                      unelevated
                      no-caps
                      @click="onClose"
                    />
                    <q-btn
                      :label="$t('back')"
                      padding="sm lg"
                      outline
                      color="primary"
                      unelevated
                      no-caps
                      @click="$refs.formAdvertiser.previous()"
                    />
                    <q-btn :label="$t('next')" padding="sm lg" unelevated no-caps type="submit" color="primary" />
                  </q-card-actions>
                </q-card>
              </q-form>
            </q-step>

            <q-step name="access" :title="$t('adjusts')" icon="add_comment">
              <q-card flat>
                <q-separator />
                <q-card-section class="row q-col-gutter-md q-pa-none q-pt-lg">
                  <div class="col-6">
                    <q-select
                      outlined
                      label="Nível"
                      map-options
                      emit-value
                      :options="[
                        { value: 1, label: 'Bronze' },
                        { value: 2, label: 'Prata' },
                        { value: 3, label: 'Ouro' }
                      ]"
                      v-model="form.level"
                    >
                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                          <q-item-section>
                            {{ scope.opt.label }}
                          </q-item-section>
                          <q-item-section avatar>
                            <q-icon name="mdi-medal-outline" size="sm" :style="iconStyle(scope.opt.value)" />
                          </q-item-section>
                        </q-item>
                      </template>

                      <template v-slot:append>
                        <q-icon
                          :name="form.level ? 'mdi-medal-outline' : ''"
                          size="sm"
                          :style="form.level ? iconStyle(form.level) : ''"
                        />
                      </template>
                    </q-select>
                  </div>

                  <div class="col-6" v-if="advertiserSelect != null">
                    <q-select
                      outlined
                      label="Status"
                      map-options
                      emit-value
                      :options="[
                        { value: 0, label: 'Pendente', disable: true },
                        { value: 2, label: 'Pausado' },
                        { value: 1, label: 'Ativado' },
                        { value: 3, label: 'Desativado' }
                      ]"
                      v-model="form.status"
                    ></q-select>
                  </div>
                </q-card-section>
                <!-- </q-card> -->

                <div class="col-12 q-gutter-sm">
                  <q-item class="q-pl-none q-mt-lg">
                    <q-item-section side>
                      <q-item-label class="text-weight-medium">Gerenciar Acessos: </q-item-label>
                    </q-item-section>
                  </q-item>
                </div>
                <q-form @submit="formSubmit">
                  <q-card flat>
                    <q-card-section class="row q-col-gutter-md q-pa-none">
                      <div class="col-12">
                        <q-input
                          outlined
                          v-model="form.login_email"
                          type="email"
                          :label="$t('access') + ' *'"
                          lazy-rules
                          :rules="[isRequired, isEmail]"
                        >
                          <template v-if="advertiserSelect" v-slot:after>
                            <q-btn class="q-py-sm" unelevated color="positive" icon="mdi-plus" @click="addAccess" />
                          </template>
                        </q-input>
                      </div>
                      <div class="row col-12" v-if="advertiserSelect">
                        <div class="row col-12 justify-between" v-for="(user, index) in form.accessList" :key="index">
                          <q-input
                            v-if="index > 0"
                            :class="form.type == 'agencia' ? 'col-3' : 'col-5'"
                            outlined
                            v-model="user.name"
                            type="text"
                            label="Nome *"
                            lazy-rules
                            :rules="[isRequired]"
                            :readonly="!!form.accessList[index].id"
                          />
                          <q-input
                            v-if="index > 0"
                            :class="form.type == 'agencia' ? 'col-4' : 'col-6'"
                            outlined
                            v-model="user.email"
                            type="text"
                            label="Email *"
                            lazy-rules
                            :rules="[isRequired, isEmail]"
                            :readonly="!!form.accessList[index].id"
                          />
                          <q-select
                            v-if="index > 0 && form.type == 'agencia'"
                            :readonly="!!!user.id"
                            class="col-4"
                            v-model="user.campaign_id"
                            :options="listCampaignsByAgency"
                            map-options
                            emit-value
                            multiple
                            use-chips
                            label="Campanhas"
                            outlined
                            option-value="id"
                            option-label="name"
                            @input="updateAccessList"
                          >
                            <q-tooltip
                              v-if="!!!user.id"
                              content-class="bg-secondary text-white"
                              content-style="font-size: 12px"
                              anchor="top middle"
                              self="center middle"
                            >
                              Antes de selecionar as campanhas, salve o usuário.
                            </q-tooltip>
                          </q-select>

                          <q-btn
                            v-if="index > 0"
                            class="q-mb-md"
                            unelevated
                            clearable
                            clear-icon="mdi-close"
                            color="negative"
                            icon="mdi-close"
                            @click="showDeleteUser(index)"
                          />
                        </div>
                      </div>
                    </q-card-section>
                    <q-card-actions align="right">
                      <q-btn
                        :label="$t('cancel')"
                        padding="sm lg"
                        outline
                        color="primary"
                        unelevated
                        no-caps
                        @click="onClose"
                      />
                      <q-btn
                        :label="$t('back')"
                        padding="sm lg"
                        outline
                        color="primary"
                        unelevated
                        no-caps
                        @click="$refs.formAdvertiser.previous()"
                      />
                      <q-btn
                        :label="step !== 'access' ? $t('next') : $t('save')"
                        padding="sm lg"
                        unelevated
                        no-caps
                        type="submit"
                        color="primary"
                      />
                    </q-card-actions>
                  </q-card>
                </q-form>
              </q-card>
            </q-step>
          </q-stepper>
        </q-card-section>
        <q-separator inset />
      </q-card>
    </q-form>
  </q-page>
</template>

<script>
import AdvertiserMixin from '../../mixins/AdvertiserMixin';
import AdvertiserService from '../../services/AdvertiserService';
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';

export default {
  name: 'FormAdvertiser',
  mixins: [AdvertiserMixin, AdvertiserService, AuthService, UserService],
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      isCheckingCnpj: false,
      cnpjInputRef: null,
      isCnpjDataAutoFilledInvoice: false,
      isCnpjDataAutoFilled: false,
      isCnpjValid: false,
      advertiserStatus: 0,
      step: 'company',
      isento: false,
      listCampaignsByAgency: [],
      form: {
        type: 'anunciante',
        trademark: null,
        business_name: null,
        cnpj: null,
        address: null,
        address_number: null,
        address_other: null,
        zip_code: null,
        state: null,
        city: null,
        country: null,
        contact_name: null,
        contact_email: null,
        phone_number: null,
        invoice_type: 'brasil',
        invoice_registration_code: null,
        invoice_inscricao_municipal: null,
        invoice_inscricao_estadual: null,
        invoice_postal_code: null,
        invoice_address: null,
        invoice_city: null,
        invoice_state: null,
        invoice_phone: null,
        invoice_deadline: null,
        invoice_email: null,
        login_email: null,
        accessList: [],
        level: null,
        status: null
      },
      userId: null
    };
  },

  created() {
    this.setValue();
    this.getCampaignsByAgency();
  },

  methods: {
    updateAccessList(value) {
      if (value.includes(0) && value[0] !== 0) {
        this.form.accessList = this.form.accessList.map((item) => {
          if (item.campaign_id.includes(0)) {
            return {
              ...item,
              campaign_id: [0]
            };
          }
          return item;
        });
      } else if (value[0] === 0 && value.length > 1) {
        this.form.accessList = this.form.accessList.map((item) => {
          if (item.campaign_id.includes(0) && item.campaign_id.length > 1) {
            return {
              ...item,
              campaign_id: item.campaign_id.slice(1)
            };
          }
          return item;
        });
      } else if (value.length === 0) {
        this.form.accessList = this.form.accessList.map((item) => {
          if (item.campaign_id.length === 0) {
            return {
              ...item,
              campaign_id: [0]
            };
          }
          return item;
        });
      }
    },

    async cnpjExists() {
      if (this.isCheckingCnpj) {
        return;
      }

      try {
        this.isCheckingCnpj = true;

        const queryString = this.id ? `cnpj=${this.form.cnpj}%26id=${this.id}` : `cnpj=${this.form.cnpj}`;
        const response = await this.$http.get(
          '/redirect?app=ADVERTISER&path=/api/get/advertiser/checkcnpj/?' + queryString
        );

        if (response && response.data && !response.data.exists) {
          this.isCnpjValid = true;
          return true;
        } else {
          return 'Não será possível cadastrar o CNPJ / Nº de registro';
        }
      } catch (error) {
        console.error('Erro ao verificar a existência do CNPJ:', error);
        return false;
      } finally {
        this.isCheckingCnpj = false;
      }
    },

    async setValue() {
      this.isento = !!this.form.invoice_inscricao_estadual;
      if (this.id) {
        this.onLoading(true);

        await this.getAdvertiserById(this.id);
        //await this.getAdvertiserUser();
        await this.getAdvertiserAccess(this.id);

        for (let i = 1; i < this.form.accessList.length; i++) {
          this.form.accessList[i].campaign_id = await this.getCampaignsByUser(this.id, this.form.accessList[i].id);
          if (this.form.accessList[i].campaign_id.length === 0) this.form.accessList[i].campaign_id = [0];

          if (
            JSON.stringify(this.form.accessList[i].campaign_id) ===
            JSON.stringify(this.listCampaignsByAgency.slice(1).map((item) => item.id))
          )
            this.form.accessList[i].campaign_id = [0];
        }

        this.onLoading(false);
      } else this.ActionSetAdvertiserSelect();
    },

    async getCampaignsByUser(agencyId, userId) {
      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=ADVERTISER&path=/api/get/agency_user/campaign/${agencyId}/${userId}`
        );
        if (status === 200) {
          return data.map((item) => item.id);
        }
      } catch (error) {
        this.errorNotify(error);
      }
    },

    async getCampaignsByAgency() {
      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=ADVERTISER&path=/api/get/agency/campaigns/${this.id}`
        );
        if (status === 200) {
          this.listCampaignsByAgency = [{ id: 0, name: 'TODAS AS CAMPANHAS' }, ...data];
        }
      } catch (error) {
        this.errorNotify(error);
      }
    },

    async getAdvertiserById(id) {
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=ADVERTISER&path=/api/get/advertiser/details/' + id
        );
        if (status === 200) {
          this.advertiserStatus = data.status;
          this.ActionSetAdvertiserSelect(data);
          this.setValuesAdvertise();
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async getAdvertiserAccess(id) {
      try {
        const { data, status } = await this.$http.get('/v2/advertiser/' + id);
        if (status === 200) {
          this.form.accessList = data.map((item) => ({ ...item, campaign_id: [0] }));
          this.form.login_email = data[0].email;
          this.userId = data[0].id;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    setValuesAdvertise() {
      this.form.type = this.advertiserSelect.type;
      this.form.trademark = this.advertiserSelect.trademark;
      this.form.business_name = this.advertiserSelect.business_name;
      this.form.cnpj = this.advertiserSelect.cnpj;
      this.form.zip_code = this.advertiserSelect.zip_code;
      this.form.address = this.advertiserSelect.address;
      this.form.address_number = this.advertiserSelect.address_number;
      this.form.address_other = this.advertiserSelect.address_other;
      this.form.state = this.advertiserSelect.state;
      this.form.city = this.advertiserSelect.city;
      this.form.country = this.advertiserSelect.country;
      this.form.contact_name = this.advertiserSelect.contact_name;
      this.form.contact_email = this.advertiserSelect.contact_email;
      this.form.phone_number = this.advertiserSelect.phone_number;
      this.form.invoice_inscricao_estadual = this.advertiserSelect.invoice_inscricao_estadual;
      this.form.invoice_inscricao_municipal = this.advertiserSelect.invoice_inscricao_municipal;
      this.form.invoice_address = this.advertiserSelect.invoice_address;
      this.form.invoice_company_name = this.advertiserSelect.invoice_company_name;
      this.form.invoice_type = this.advertiserSelect.invoice_type;
      this.form.invoice_city = this.advertiserSelect.invoice_city;
      this.form.invoice_state = this.advertiserSelect.invoice_state;
      this.form.invoice_postal_code = this.advertiserSelect.invoice_postal_code;
      this.form.invoice_phone_code = this.advertiserSelect.invoice_phone_code;
      this.form.invoice_phone = this.advertiserSelect.invoice_phone;
      this.form.invoice_deadline = this.advertiserSelect.invoice_deadline;
      this.form.invoice_registration_code = this.advertiserSelect.invoice_registration_code;
      this.form.invoice_email = JSON.parse(this.advertiserSelect.invoice_email);
      this.form.level = this.advertiserSelect.level;
      this.form.status = this.advertiserSelect.status;

      if (this.form.invoice_inscricao_estadual === null) {
        this.isento = true;
      }
    },

    onClose() {
      this.$router.push({ name: 'Advertiser' });
    },

    formSubmit() {
      if (this.advertiserSelect != null) this.onUpdate();
      else this.onSave();
    },

    async onSave() {
      this.onLoading(true);
      try {
        const formCreate = {
          ...this.form,
          invoice_email: JSON.stringify([this.form.invoice_email])
        };
        delete formCreate.accessList;
        //const { data, status } = await this.createAdvertiser(formCreate);
        const { status } = await this.createAdvertiser(formCreate);
        if (status === 200 || status === 201) {
          await this.$router.push('/advertiser');

          //this.successNotify(data.message);
          this.successNotify('Anunciante cadastrado com sucesso!');
        }
      } catch (error) {
        this.showInfoDialog(JSON.stringify(error.response.data.login));
      } finally {
        this.onLoading(false);
      }
    },

    async onUpdate() {
      this.onLoading(true);

      try {
        const formCreate = {
          ...this.form,
          invoice_email: JSON.stringify(this.form.invoice_email)
        };
        delete formCreate.accessList;
        delete formCreate.login_email;
        delete formCreate.level;

        if (this.form.level != this.advertiserSelect.level) await this.updateAdvertiserLevel(this.id, this.form.level);

        if (this.form.status != this.advertiserSelect.status)
          await this.updateAdvertiserStatus(this.id, this.form.status);

        await this.updateAdvertiser(this.id, formCreate);

        await this.updateAccessEmail(this.userId, this.form.login_email);

        // for (let i in this.form.accessList) {
        for (let i = 0; i < this.form.accessList.length; i++) {
          if (!this.form.accessList[i].id) {
            this.form.accessList[i].id = (await this.createAccessAdvertiser(i)).data.post_redirect.id;
          }

            if (this.form.accessList[i + 1]?.id){

            let campaign_id = this.form.accessList[i + 1].campaign_id;
            if (
              this.form.accessList[i + 1].campaign_id.length === 1 &&
              this.form.accessList[i + 1].campaign_id[0] === 0
            )
              campaign_id = [];

            await this.updateAccessCampaignByAgency(
              this.form.accessList[i + 1].id,
              this.id,
              campaign_id
            );
          }
        }
        // await this.getAdvertiserAccess(this.id);
        this.successNotify('Anunciante atualizado!');
      } catch (error) {
        if (error.response?.data?.login?.email[0]) this.errorNotify(error.response.data.login.email[0]);
        else if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.$forceUpdate();
        this.onLoading(false);
      }
    },

    async updateAccessCampaignByAgency(user_id, agency_id, campaign_id) {
      return await this.$http.post(`/v2/redirect?app=ADVERTISER&path=/api/user/campaigns`, {
        params: {
          data: {
            user_id: user_id,
            agency_id: parseInt(agency_id),
            campaign_id: campaign_id
          }
        }
      });
    },

    async createAccessAdvertiser(i) {
      let advertiserDataAccess = {
        login_email: this.form.accessList[i].email,
        name: this.form.accessList[i].name,
        advertiser_id: this.id
      };
      return await this.$http.post(`/v2/advertiser/settings/user`, {
        params: {
          data: advertiserDataAccess
        }
      });
    },

    showDialog() {
      this.$q.dialog({
        title: 'Atenção<em>!</em>',
        message: `<span> Limite de 6 acessos atingido!</span>`,
        ok: { color: 'positive' },
        persistent: true,
        html: true
      });
    },
    showDeleteUser(index) {
      if (!this.form.accessList[index].id) this.form.accessList.splice(index, 1);
      else
        this.$q
          .dialog({
            title: 'Atenção<em>!</em>',
            message: `<span> Deseja excluir o usuário <b>${this.form.accessList[index].name}</b></span>`,
            cancel: { color: 'negative' },
            focus: 'cancel',
            ok: { color: 'positive' },
            persistent: true,
            html: true
          })
          .onOk(() => {
            this.removeAccess(index);
          });
    },
    addAccess() {
      if (this.form.accessList.length <= 5) this.form.accessList.push({ name: '', email: '' });
      else this.showDialog();
    },

    async removeAccess(index) {
      try {
        const { status } = await this.$http.delete(
          `/v2/advertiser/settings/user/${this.form.accessList[index].id}/${this.id}`
        );
        if (status === 200 || status === 201) {
          this.successNotify(`Acesso do usuário ${this.form.accessList[index].name} excluído!`);
          this.form.accessList.splice(index, 1);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async getCnpjDataForAdvertiser() {
      try {
        this.onLoading(true);
        const cleanedCnpj = this.form.cnpj.replace(/\D/g, '');
        const { data, status } = await this.getCnpjData(cleanedCnpj);
        if (status === 200) {
          this.form.business_name = data.razao_social;
          this.form.fantasy_name = data.nome_fantasia;
          this.form.address = data.endereco;
          this.form.address_number = data.numero;
          this.form.address_other = data.complemento;
          this.form.zip_code = data.cep;
          this.form.state = data.estado;
          this.form.city = data.cidade;
          this.form.country = data.pais;
          //Preenchendo campos da aba de Fatuamento
          this.form.invoice_registration_code = this.form.cnpj;
          this.form.invoice_company_name = data.razao_social;
          this.form.invoice_address = data.endereco;
          this.form.invoice_city = data.cidade;
          this.form.invoice_state = data.estado;
          this.form.invoice_postal_code = data.cep;

          this.isCnpjDataAutoFilled = true;
          this.isCnpjDataAutoFilledInvoice = true;
        }
      } catch (error) {
        this.infoNotify(
          'Não foi possível realizar a validação do CNPJ fornecido. Verifique se CNPJ preenchido está correto ou prossiga com a validação manual.'
        );
      } finally {
        this.onLoading(false);
      }
    },

    async getCnpjDataForInvoice(value) {
      const isValidCnpj = this.isCNPJ(value);
      if (isValidCnpj && typeof isValidCnpj == 'boolean') {
        try {
          this.onLoading(true);
          const cleanedCnpj = value.replace(/\D/g, '');
          const { data, status } = await this.getCnpjData(cleanedCnpj);
          if (status === 200) {
            this.form.invoice_company_name = data.razao_social;
            this.form.invoice_address = data.endereco;
            this.form.invoice_city = data.cidade;
            this.form.invoice_state = data.estado;
            this.form.invoice_postal_code = data.cep;

            this.isCnpjDataAutoFilledInvoice = true;
          }
        } catch (error) {
          this.infoNotify(
            'Não foi possível realizar a validação do CNPJ fornecido. Verifique se CNPJ preenchido está correto ou prossiga com a validação manual.'
          );
        } finally {
          this.onLoading(false);
        }
      } else {
        this.isCnpjDataAutoFilledInvoice = false;
      }
    },

    async validateCnpjAndFetchData(value) {
      await this.$nextTick();
      await this.$refs.cnpjInputRef.validate();
      const isValidCnpj = this.isCNPJ(value);
      if (this.isCnpjValid && isValidCnpj && typeof isValidCnpj == 'boolean') {
        this.getCnpjDataForAdvertiser();
        this.isCnpjValid = false;
      } else {
        this.isCnpjDataAutoFilled = false;
      }
    }
  }
};
</script>
